<template>
    <div>
        <div class="headBannerBox">
            <el-carousel ref="carousel" indicator-position="none" @change="changeCarousel" arrow="never"
                :initial-index="initialIndex" :autoplay="true" height="330px">
                <el-carousel-item v-for="(item, index) in carousels" :key="index">
                    <div class="bannerBI" @click.stop="goDetail(item.id)">
                        <img :src="item.img" />
                        <span class="bannerImgTitle textEli" :title="item.name">
                            {{ item.name }}
                        </span>
                    </div>
                </el-carousel-item>
            </el-carousel>
            <div class="btns next" @click.stop="handele('next')"><img class="change" src="@/assets/img/headNext.png">
            </div>
            <div class="btns previous" @click.stop="handele('pre')"><img class="change" src="@/assets/img/headPre.png">
            </div>
            <div class="pagination-wrap">
                <ul>
                    <li v-for="(item, index) in carousels" :key="index" :class="{ active: initialIndex === index }"></li>
                </ul>
            </div>
        </div>
        <div class="mainPageBody">
            <div class="pageBodyLeft">
                <div class="bodyLeftContent">
                    <div class="contentTitle">
                        <label class="titleBar"></label>
                        <span class="titleText">新闻<span class="garyColor">资讯</span></span>
                        <span class="Textmore"><a href="javascript:;" @click.stop="goNewsList">更多</a></span>
                    </div>
                    <div class="contentContent">
                        <a href="javascript:;" v-for="(item, index) in news" :key="index" @click.stop="goDetail(item.id)">
                            <div class="contentLine">
                                <span class="contentText textEli" :title="item.title">{{ item.title }}</span>
                                <span class="contentTextDate">{{ parseTime(item.createdAt, '{m}/{d}') }}</span>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="bodyLeftContent">
                    <div class="contentTitle">
                        <label class="titleBar"></label>
                        <span class="titleText">活动<span class="garyColor">信息</span></span>
                        <span class="Textmore"><a href="javascript:;" @click.stop="goActiveList">更多</a></span>
                    </div>
                    <div class="contentContent">
                        <a href="javascript:;" v-for="(item, index) in actives" :key="index"
                            @click.stop="goDetail(item.id)">
                            <div class="contentLine">
                                <span class="contentText textEli" :title="item.title">{{ item.title }}</span>
                                <span class="contentTextDate">{{ parseTime(item.createdAt, '{m}/{d}') }}</span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="pageBodyRight">
                <div class="bodyRightHead">
                    <div class="insideBox">
                        <div class="ewmTitle">浙江之声</div>
                        <div class="ewmImg1"></div>
                    </div>
                    <div class="insideBox">
                        <div class="ewmTitle">浙江新闻广播</div>
                        <div class="ewmImg2"></div>
                    </div>
                </div>
                <div class="bodyRightBody">
                    <label class="borderTop"></label>
                    <div class="RightBodyBody">
                        <div class="insideTop">
                            <div class="weiboPic"></div>
                            <div class="weiboCon">
                                <div class="weiboConTop">
                                    <span class="weiboName">浙江之声</span>
                                    <span class="weiboLoc">浙江 杭州</span>
                                </div>
                                <div class="weiboBtn">
                                    <iframe
                                        src="https://widget.weibo.com/relationship/followbutton.php?btn=red&amp;style=1&amp;uid=1718259005&amp;width=67&amp;height=24&amp;language=zh_cn"
                                        width="67" height="24" frameborder="0" scrolling="no" marginheight="0"></iframe>
                                </div>
                            </div>
                        </div>
                        <div class="insideBot "></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 底部轮播图 -->
        <div class="photoBlock">
            <div class="contentTitle">
                <label class="titleBar"></label>
                <span class="titleText">主持人</span>
                <!--<span class="Textmore"><a href="/t/zhuchirenlunbo.html">更多</a></span>-->
            </div>
            <div class="Box" @click="goHosterDetail">
                <span class="btnl btn" @mouseenter="handleMouseenter" @mouseleave="handleMouseleave"
                    @click.stop="handleLeft"></span>
                <span class="btnr btn" @mouseenter="handleMouseenter" @mouseleave="handleMouseleave"
                    @click.stop="handleRight"></span>
                <vue-seamless-scroll ref="seamlessScroll" :data="listData" :class-option="classOption" class="conbox"
                    :class="{ 'hovercon': isHover }">
                    <ul>
                        <li :data-id="item.id" class="cur" v-for="(item, index) in listData" :key="index">
                            <img :data-id="item.id" :src="item.url" />
                        </li>
                    </ul>
                </vue-seamless-scroll>
            </div>
        </div>

        <!-- 联系方式 -->
        <div class="contactBlock">
            <div class="contentTitle">
                <label class="titleBar"></label>
                <span class="titleText">联系<span class="garyColor">我们</span></span>
                <span class="contactTel">
                    <label class="contectTel"></label>
                    <span class="contectText">0571-88110110</span>
                </span>
                <span class="contactTel">
                    <label class="contectLoc"></label>
                    <span class="contectText">浙江省杭州市莫干山路111号</span>
                </span>
            </div>
        </div>

    </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import { APPID, HOMELB, NEWS, ACTIVE, HOST } from '@/utils/api/config'
import { parseTime } from "@/utils/tools"
export default {
    components: {
        vueSeamlessScroll,
    },
    data() {
        return {
            initialIndex: 0,
            carousels: [
                // {
                //     img: 'http://o.cztvcloud.com/195/posts/2023/12/31/d4a595473f17f6eb755855bc7091dd8d.jpeg',
                //     title: '2023年首届千岛湖年俗生活节开幕'
                // }, {
                //     img: 'http://o.cztvcloud.com/195/posts/2023/09/28/68db1ea45ac8fdb177f4b13e31f81cea.png',
                //     title: '民宿创新点亮乡旅 浙韵千宿·安吉县第二届民宿（乡宿）创新创业大赛赋能乡村振兴'
                // }, {
                //     img: 'http://o.cztvcloud.com/195/posts/2023/09/28/6b188f5da9f034d64a720b9e34f02948.jpg',
                //     title: '巾帼添彩 创赢未来！浙江省巾帼创新创业大赛科技创新组比赛即将开启'
                // }, {
                //     img: 'http://o.cztvcloud.com/195/posts/2023/05/04/d454c4ee8a5e107e45de6a7d3b54421b.jpg',
                //     title: '2023天台山华顶云锦杜鹃节摄影和短视频大赛'
                // }, {
                //     img: 'http://o.cztvcloud.com/195/posts/2023/03/15/9178514f4fc94f5cc3fc9eaeea80a763.png',
                //     title: '闹市区多了家爱心咖啡坊，全国首个“蜗牛之翼”门店在杭开业'
                // }
            ],
            news: [
                //     {
                //     title: '浙江仙居：暖心服务助力当地旅游发展',
                //     date: '01/16'
                // }, {
                //     title: '灭火时是超级英雄，平日里是大男孩，萧山瓜沥消防开展趣味训练',
                //     date: '01/16'
                // }, {
                //     title: '开展燃气安全隐患排查 保障人民群众生命财产安全',
                //     date: '01/16'
                // }, {
                //     title: '每天劳动一小时 共建美丽新村庄',
                //     date: '01/16'
                // }, {
                //     title: '多样化采集，只为城市整洁有序',
                //     date: '01/16'
                // }, {
                //     title: '星巴克联名《大闹天宫》，重新定义咖啡外送体验',
                //     date: '01/16'
                // }, {
                //     title: '“LIAN上”浙礼|迎春送暖——过了腊八就是年',
                //     date: '01/16'
                // }, {
                //     title: '让独居老人安“燃”过冬',
                //     date: '01/15'
                // }, {
                //     title: '湖州长兴虹星桥镇：以练促防 筑牢森林火灾防护网',
                //     date: '01/15'
                // }, {
                //     title: '安吉农商银行山川支行举办“大手拉小手 反诈反假在行动”活动',
                //     date: '01/15'
                // }, {
                //     title: '引领时代风尚，书写墨韵清风',
                //     date: '01/15'
                // }, {
                //     title: '照顾胞弟五十余年！德清这位老人说……',
                //     date: '01/16'
                //}
            ],
            actives: [
                //{
                //     title: '特色企服再行动！ 第二届西投云ZONE燃动季系列活动圆满落幕',
                //     date: '01/02'
                // }, {
                //     title: '年底“御寒神器”消费大爆发  银泰百货雪地靴销售额暴涨6倍',
                //     date: '12/29'
                // }, {
                //     title: '杭实集团发布“实业智投”数字平台，打造数智化投资新范式',
                //     date: '12/18'
                // }, {
                //     title: '京杭大运河畔的这所国际学校十岁啦',
                //     date: '12/18'
                // }, {
                //     title: '激情澎湃，火力全开！第二届西投云ZONE燃动季系列活动超燃开赛',
                //     date: '12/17'
                // }, {
                //     title: '这场职工篮球赛很精彩！',
                //     date: '12/11'
                // }, {
                //     title: '艺术之城！千岛湖畔有了新地标~',
                //     date: '11/28'
                // }, {
                //     title: '才聚“精彩淳西南”  淳安举办首届乡村高质量发展论坛',
                //     date: '11/20'
                // }, {
                //     title: '新一届浙体产业“领军人物”“酷玩大使”揭晓',
                //     date: '11/18'
                // }, {
                //     title: '冬季应急大练兵 救援队伍火力开',
                //     date: '11/17'
                // }, {
                //     title: '2023千岛湖创意生活周在杭州市淳安县千岛湖秀水广场正式启幕',
                //     date: '11/10'
                // }, {
                //     title: '重新走进飞来峰，看见杭州群山之间的石头记',
                //     date: '11/02'
                // }
            ],
            listData: [
                //     {
                //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/85b17eac80ed7e3a7223c1505dea16c2.png'
                // }, {
                //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/61fc42424e22346d782a256cab4d19a7.png'
                // }, {
                //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/ae99c63f7cf3c827fedce26cc2e702b9.png'
                // }, {
                //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/47336e0783b9ab6db30a35f072e2a2e0.png'
                // }, {
                //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/4a8315bb3df45da708271a80f9b22ce4.png'
                // }, {
                //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/2244ea08024e026d217543aff88182c0.jpeg'
                // }, {
                //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/1808835963f20e33b99d0da2b24f0125.png'
                // }, {
                //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/220c0bd04f06d35229384a6df23027db.jpeg'
                // }, {
                //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/e60779660689d89ded09e9355479a919.png'
                // }, {
                //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/697b57251ea66a9d82226bb074f72590.jpeg'
                // }, {
                //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/996565d283779d07bced98bdb06f69fb.png'
                // }, {
                //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/81e7d9cddbae143288bf4411e2b65fea.jpeg'
                // }, {
                //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/ad9f4aef457e2d2887a01f5e537547b0.jpeg'
                // }, {
                //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/5b587e65d2e2070b2c15134d04c39289.png'
                // }, {
                //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/f7c020a95df4a94e42e1dc64e3af5ae1.png'
                // }, {
                //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/485497f3eab0669540964b70d9d022a2.png'
                // }, {
                //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/90f4df49242c7cdeadcb69c1bb1eb2cb.png'
                // }, {
                //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/2a1ec77a84dfcc3f8adb2a318afc3f6c.png'
                // }, {
                //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/08d2b73b61b2aec196521530ee2e8389.png'
                // }, {
                //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/d4b52d2f1a116821a2c49a8916503ab5.jpeg'
                // }, {
                //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/b26a89c352281f1b5d082054bd345ff0.png'
                // }, {
                //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/bc08c44fa1d13e9a7a58f9d33aa67701.jpeg'
                // }, {
                //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/3e3c6bb245858b4a472eb41a5ab7f32c.jpeg'
                // }, {
                //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/7fcd544fb819d9e94e0a766e8aafe1cd.jpeg'
                // }, {
                //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/94df67d54089eaca61f06d900dff7137.jpeg'
                // }, {
                //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/2dff481ccca889cbeac3fb5eb292a11b.jpeg'
                // }, {
                //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/6bd436c1210b15effb7617b764d96600.jpeg'
                // }, {
                //     url: 'http://o.cztvcloud.com/195/thumb/2018/05/16/5377af6789cb62a0b5c6b2c1d08a98ee.jpeg'
                // }
            ],
            isHover: false,
            classOption: {
                autoPlay: true,
                step: 3,
                direction: 2,
                singleWidth: 120,
                switchSingleStep: 120,
                switchDelay: 200,
                navigation: false
            }
        }
    },
    created() {
        console.log(window.location.href)
        this.getCarousel()
        this.getNewsList()
        this.getActiveList()
        this.getHostList()
        this.getWeiboInfo()
    },
    methods: {
        changeCarousel(val) {
            this.initialIndex = val;
        },
        handele(val) {
            if (val === 'pre') {
                this.$refs.carousel.prev();
            } else {
                this.$refs.carousel.next();
            }
        },
        //跳转主持人详情 //这个插件问题挺大
        goHosterDetail(e) {
            const id = e.target.dataset.id;
            if (id) {
                let { href } = this.$router.resolve({ path: '/hosterDetail/' + id })
                window.open(href, '_blank')
            }
        },
        //跳转轮播图详情
        goDetail(id) {
            // this.$router.push({ path: '/homeDetail/' + id })
            let { href } = this.$router.resolve({ path: '/homeDetail/' + id })
            window.open(href, '_blank')
        },
        //跳转到新闻列表
        goNewsList() {
            this.$router.push({ path: "/newsList" })
        },
        //跳转到活动详情
        goActiveList() {
            this.$router.push({ path: "/activeList" })
        },
        //获取轮播图
        getCarousel() {
            this.axios.get(`api/fusion/pc/news/list?appId=${APPID}&channelId=${HOMELB}`).then(res => {
                if (res.code !== '200') {
                    this.$message.error(res.msg)
                    return
                }
                console.log('res----轮播图', res.data.list)
                this.carousels = (res.data.list || []).map(v => {
                    let path = v?.cover[0]?.path || ''
                    // let str = 'http://shixiantest.oss-cn-hangzhou.aliyuncs.com/'
                    return {
                        name: v.title,
                        // img: path.indexOf('http') == -1 && path ? str + path : path,
                        img: path,
                        id: v.id
                    }
                });
            })
        },
        //获取首页12条新闻
        getNewsList() {
            this.axios.get(`api/fusion/pc/news/list?appId=${APPID}&channelId=${NEWS}&page=1&limit=12`, {}).then(res => {
                if (res.code !== '200') {
                    this.$message.error(res.msg)
                    return
                }
                this.news = res.data.list || []
            })
        },
        //获取活动12条新闻
        getActiveList() {
            this.axios.get(`api/fusion/pc/news/list?appId=${APPID}&channelId=${ACTIVE}&page=1&limit=12`).then(res => {
                if (res.code !== '200') {
                    this.$message.error(res.msg)
                    return
                }
                this.actives = res.data.list || []
            })
        },
        //获取主持人轮播
        getHostList() {
            this.axios.get(`api/fusion/pc/news/list?appId=${APPID}&channelId=${HOST}&page=1&limit=1000`).then(res => {
                if (res.code !== '200') {
                    this.$message.error(res.msg)
                    return
                }
                this.listData = (res.data.list || []).map(v => {
                    let path = v?.cover[0]?.path || ''
                    // let str = 'http://shixiantest.oss-cn-hangzhou.aliyuncs.com/'
                    return {
                        name: v.title,
                        // img: path.indexOf('http') == -1 && path ? str + path : path,
                        url: path,
                        id: v.id
                    }
                });
            })
        },
        handleMouseenter() {
            this.$refs.seamlessScroll.enter()
            this.isHover = true
            // this.classOption.autoPlay = false
            // this.$set(this.classOption,'autoPlay',false)
            // this.$refs.seamlessScroll._initMove()
            // this.classOption.navigation = true
        },
        handleMouseleave() {
            this.$refs.seamlessScroll.leave()
            this.isHover = false
            // this.classOption.autoPlay = true
            // this.classOption.navigation = false
            // this.$set(this.classOption,'autoPlay',true)
            // this.$refs.seamlessScroll._startMove()
            // this.$refs.seamlessScroll._initMove()
        },
        handleLeft() {
            this.$refs.seamlessScroll.leftSwitchClick()
        },
        handleRight() {
            this.$refs.seamlessScroll.rightSwitchClick()
        },
        //获取微博信息
        getWeiboInfo() {
            $.ajax({
                url: 'http://widget.weibo.com/public/aj_namecard.php?fuid=1718259005&language=zh_cn&callback=STK_15253162985245',
                type: 'get',
                dataType: 'JSONP',
                success: function (data) {
                    console.log(data)
                    $(".insideBot").html(data.data);
                    $(".usercard_container,.blog_source,usercard_layer,.WB_widget,.WB_follow_ex,.WB_floatContainer").hide();
                    $(".blog_info").before('<i>. . .</i>');
                    if ($(".insideBot .blog_content").text().length > 80) {
                        $(".insideBot .blog_content").text($(".insideBot .blog_content").text().substring(0, 94));
                    }
                },
                error: function (msg) {
                    console.log(msg)
                }
            });

        }
    }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
<style>
.conbox.hovercon div:first-child {
    color: #fff;
    transition: all linear .2s !important;
}
.insideBot .get_blog_link {
    color: #0078b6 !important;
    text-decoration: none !important;
    font-size: 12px;
    position: absolute;
    bottom: 5px;
}

.insideBot .blog_time {
    font-size: 12px;
    color: #999;
    position: absolute;
    width: 100%;
    text-align: right;
    right: 0;
    bottom: 5px;
}
.blog_content{
    max-height:142px;
    overflow: hidden;
}
</style>